<template>
    <div>
        <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
                <div class="row">
                    <div class="col-md-6">
                        <strong>Pending List</strong>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <b-row>
                    <b-col md='12' lg='12' xl='12'>
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                            <div class="row">
                                <div class="col-md-3">
                                    <b-form-group
                                    label-cols-md="12"
                                    >
                                    <template v-slot:label>
                                    Search
                                    </template>
                                    <b-form-input
                                        id="search"
                                        v-model="search.search"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-3">
                                        <b-form-group
                                        label-cols-md="12"
                                        >
                                        <template v-slot:label>
                                        Select Method
                                        </template>
                                        <b-form-select
                                        :options="methodList"
                                            id="method_id"
                                            rows="6"
                                            v-model="search.method_id"
                                            ></b-form-select>
                                        </b-form-group>
                                </div>
                                <div class="col-md-3">
                                    <b-form-group
                                    label-cols-md="12"
                                    >
                                    <template v-slot:label>
                                    Start Date
                                    </template>
                                    <b-form-input
                                        type="date"
                                        id="search"
                                        v-model="search.start_date"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-3">
                                    <b-form-group
                                    label-cols-md="12"
                                    >
                                    <template v-slot:label>
                                    End Date
                                    </template>
                                    <b-form-input
                                        type="date"
                                        id="search"
                                        v-model="search.end_date"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12">
                                    <div style=";text-align: right;" class="mb-2">
                                        <b-button type="submit" variant="primary">Search</b-button>
                                    </div>
                                </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                        
                    </b-col>
                </b-row>
                <div class="col-md-12">
                    <div style="text-align: left;">
                        <input @change="setCheckItemCheck($event)" type="checkbox" :value="true" v-model="checkAll" /> Check All
                    </div>
                    <div style=";text-align: right;" class="mb-2">
                        <a class="btn btn-sm btn-success mt-1 mr-1" target="_blank" :href="baseUrl+'list-approve?ids='+checkItems" variant="primary">Approve</a>
                        <a class="btn btn-sm btn-success mt-1" :href="baseUrl+'list-export?ids='+checkItems" variant="primary">Export</a>
                    </div>
                </div>
                <b-overlay :show='loading'>
                    <div class="overflow-auto">
                        <b-table thead-class="bg-light text-dark" emptyText="Data Not Found" small show-empty bordered hover :items="itemList" :fields="fields">
                            <template v-slot:cell(index)="data">
                                {{ $n(data.index + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(check)="data">
                                <input @change="setCheckItem($event)" type="checkbox" v-model="data.item.check" />
                            </template>
                            <template v-slot:cell(name)="data">
                                    <span class="badge badge-success" v-if="data.item.is_valid">Valid</span>
                                    <span class="badge badge-danger" v-else>Invalid</span>
                            </template>
                            <template v-slot:cell(email)="data">
                                <router-link :to='`/users/profile?id=` + data.item.user_id' >
                                    {{ data.item.email }}
                                </router-link>
                            </template>
                            <template v-slot:cell(created_at)="data">
                                {{ data.item.created_at | dateFormat }}
                            </template>
                            <template v-slot:cell(status)="data">
                                <span class="badge badge-info" v-if="parseInt(data.item.status) === 1">Pending</span>
                                <span class="badge badge-success" v-else-if="parseInt(data.item.status) === 2">Completed</span>
                                <span class="badge badge-danger" v-else>Rejected</span>
                            </template>
                            <template v-slot:cell(action)="data">
                                <slot  v-if="$can('withdraw-status')" >
                                    <b-button v-if="data.item.status !== 1" title="Cancel" class="ml-2 btn btn-warning btn-sm" @click="changeStatus(data.item, 1)"> <i class="ri-close-circle-line"></i> </b-button>
                                    <b-button v-if="data.item.status !== 2" title="Approve" class="btn btn-success ml-2 btn-sm" @click="changeStatus(data.item, 2)"> <i class="ri-check-line"></i></b-button>
                                    <!-- <b-button v-if="data.item.status !== 3" title="Reject" class="ml-2 btn btn-danger btn-sm" @click="changeStatus(data.item, 3)"><i class="ri-close-line"></i></b-button> -->
                                    <b-button title="Reject"  v-if="data.item.status !== 3" class="btn btn-danger btn-sm ml-2" v-b-modal.modal-rej @click="edit(data.item)"><i class="ri-close-line"></i></b-button>
                                </slot>
                                <b-button v-if="$can('withdraw-delete')" title="Delete" class="ml-2 btn btn-danger btn-sm" @click="deleteItem(data.item)"><i class="ri-delete-bin-line"></i></b-button>

                            </template>
                        </b-table>
                    </div>
                </b-overlay>
                <b-pagination
                class="text-right"
                v-model="pagination.currentPage"
                :total-rows="pagination.total"
                :per-page="pagination.perPage"
                @input="searchData"
                ></b-pagination>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    <b-modal id="modal-rej"
        size="md"
        header-bg-variant="primary"
        header-text-variant="light"
        title="Reject Withdraw" hide-footer>
        <div>
            <RejectForm :id='editId'/>
    </div>
  </b-modal>
    </div>
    </template>
    <script>
    import RestApi, { baseUrl } from '../../config/api_config'
    import { ValidationObserver } from 'vee-validate'
    import iziToast from 'izitoast';
    import RejectForm from './RejectForm.vue';
    
    export default {
        components: {
            ValidationObserver,
            RejectForm
        },
        created () {
            this.loadData ()
        },
        data() {
            return {
                search: {
                    method_name: '',
                    email: '',
                    mobile_no: ''
                },
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    total: 0
                },
                editId: '',
                baseUrl: baseUrl,
                checkItems: [],
                checkAll: false
            }
        },
        computed: {
            methodList () {
                return this.$store.state.commonObj.withdrawMethodList
            },
            itemList () {
                return this.$store.state.list
            },
            fields () {
                const labels = [
                    { label: 'Sl No', class: 'text-center' },
                    { label: 'Check', class: 'text-center' },
                    { label: 'Name', class: 'text-center' },
                    { label: 'Email', class: 'text-center' },
                    { label: 'Amount', class: 'text-center' },
                    { label: 'Account Info', class: 'text-center' },
                    { label: 'Payment Method', class: 'text-center' },
                    { label: 'Status', class: 'text-center' },
                    { label: 'Date', class: 'text-center' },
                    { label: 'Action', class: 'text-center' }
                ]
    
                let keys = []
                keys = [
                { key: 'id' },
                { key: 'check' },
                { key: 'name' },
                { key: 'email' },
                { key: 'amount' },
                { key: 'mobile_no' },
                { key: 'method_name' },
                { key: 'status' },
                { key: 'created_at' },
                { key: 'action' }
                ]
                return labels.map((item, index) => {
                    return Object.assign(item, keys[index])
                })
            },
            loading () {
              return this.$store.state.static.loading
            },
            listReload () {
              return this.$store.state.static.listReload
            }
        },
        watch: {
          listReload: function (newVal) {
            if (newVal) {
                this.loadData()
            }
          }
        },
        methods: {
            setCheckItemCheck (value) {
                if (value.target.checked) {
                    this.checkItems = this.itemList.map(item => {
                        item.check = true;
                        return item.id;
                    })
                    return true
                }
                this.checkItems = this.itemList.map(item => {
                    item.check = false;
                    return item.id;
                })
            },
            setCheckItem (value= null) {
                this.checkItems = this.itemList.filter(item => item.check == true).map(item => {
                    return item.id
                })
            },
            deleteItem (item) {
                this.$swal({
                    title: 'Are you sure to delete this row ?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteData(item)
                    }
                })
            },
            deleteData (item) {
                RestApi.deleteData(baseUrl, `api/withdraw/delete/${item.id}`).then(response => {
                    if (response.success) {
                        this.$store.dispatch('mutedLoad', { listReload: true })
                        iziToast.success({
                            title: 'Success',
                            message: response.message
                        })
                    } else {
                        iziToast.error({
                            title: 'Success',
                            message: response.message
                        })
                    }
                })
            },
            edit (item) {
                this.editId = item.id
            },
            changeStatus (item, status) {
                this.$swal({
                    title: 'Are you sure to change status ?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.toggleStatus(item, status)
                    }
                })
            },
            toggleStatus (item, statusId) {
                RestApi.putData(baseUrl, `api/withdraw/status/${item.id}`, { status: statusId }).then(response => {
                    if (response.success) {
                        this.$store.dispatch('mutedLoad', { listReload: true })
                        iziToast.success({
                            title: 'Success',
                            message: response.message
                        })
                    } else {
                        iziToast.error({
                            title: 'Success',
                            message: response.message
                        })
                    }
                })
            },
            searchData () {
                this.loadData()
            },
            loadData () {
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, status: 1 })
                this.$store.dispatch('mutedLoad', { loading: true})
                RestApi.getData(baseUrl, 'api/withdraw/list', params).then(response => {
                    if (response.success) {
                        this.$store.dispatch('setList', response.data.data)
                        this.paginationData(response.data)
                    }
                    this.$store.dispatch('mutedLoad', { loading: false })
                })
            },
            paginationData (data) {
                this.pagination.perPage = parseInt(data.per_page)
                this.pagination.currentPage = parseInt(data.current_page)
                this.pagination.total = parseInt(data.total)
            }
        },
        filters: {
            subStr: function(string) {
                return string.substring(0, 80) + '...';
            }
        }
    }
    </script>